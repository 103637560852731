import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
function Checkmark(
  props: SvgIconProps & {
    'data-testid'?: string;
  }
) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'CheckmarkIcon' },
      props
    ),
    <path
      fill="currentColor"
      d="m9.056 16.17-3.47-3.47a.996.996 0 1 0-1.41 1.41l4.18 4.18c.39.39 1.02.39 1.41 0l10.58-10.58a.996.996 0 1 0-1.41-1.41z"
    />
  );
}
export default Checkmark;
